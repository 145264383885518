<!--
 * @Description: quick demo - vue2 版本页面
 * @Date: 2022-03-14 16:56:36
 * @LastEditTime: 2022-03-29 17:01:32
-->
<template>
  <div id="app">
    <div class="content" :class="$isMobile && 'content-mobile'">
      <div class="param-container" :class="$isMobile && 'param-container-mobile'" style="display:none">
        <comp-info-input
          label="sdkAppId" type="number" @change="handleValueChange($event, 'sdkAppId')"></comp-info-input>
        <comp-info-input
          label="secretKey" @change="handleValueChange($event, 'secretKey')"></comp-info-input>
        <!-- <comp-info-input
          label="userId" @change="handleValueChange($event, 'userId')"></comp-info-input> -->
        <!-- <comp-info-input
          label="roomId" type="number" @change="handleValueChange($event, 'roomId')"></comp-info-input> -->
      </div>
      <!-- rtc 房间 -->
      <comp-room
        :sdkAppId="Number(sdkAppId)"
        :secretKey="secretKey"
        :userId="userId"
        :roomId="Number(roomId)"
        :cameraId="cameraId"
        :microphoneId="microphoneId"
        :username="username"
        :checkname="checkname"
        :visitstarttime="visitstarttime"
        :id="id"
        :name="name"
        :textarea2="textarea2"
        @close="getset"></comp-room>
    </div>
  </div>
</template>

<script>
// import compNav from '@/components/comp-nav.vue';
// import compGuidance from '@/components/comp-guidance.vue';
import compInfoInput from '@/components/comp-info-input.vue';
// import compDeviceSelect from '@/components/comp-device-select.vue';
import compRoom from '@/components/comp-room.vue';
import { clearUrlParam } from '@/utils/utils';
import{getRoomVisit} from '@/utils/api'
export default {
  name: 'App',
  components: {
    // compNav,
    // compGuidance,
    compInfoInput,
    // compDeviceSelect,
    compRoom,
  },
  data() {
    return {
      sdkAppId: 0,
      secretKey: '',
      userId: '',
      roomId: 0,
      cameraId: '',
      microphoneId: '',
      set:false,
      username :'',
      checkname:'',
      visitstarttime:"",
      id:'',
      name:'',
      textarea2:'',
    };
  },
  methods: {
    handleValueChange(value, key) {
      // console.log(key,value);
      this[key] = value;
    },
    getset(close){
      // console.log(close);
      this.set = close
    }
  },
  created(){
    // console.log('111',this.$store.state.username);
    this.userId = this.$store.state.username
    this.roomId = this.$route.query.rooid;
    this.id = this.$route.query.id
    getRoomVisit({
        visitId:this.id
    }).then(res =>{
      // console.log(res);
      this.username = res.data.data.user
      this.checkname = res.data.data.admin
      this.name = res.data.data.Recording.name
      this.textarea2 = res.data.data.Recording.text
    })
    // console.log('房间号',this.roomId);
  },
  mounted() {
    clearUrlParam();
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  .content {
    width: 80%;
    margin: 0 auto;
    max-width: 1320px;
    &.content-mobile {
      width: 100%;
      padding: 0 16px 20px;
    }
    .label {
      margin: 14px 0 6px;
      text-align: left;
      font-weight: bold;
    }
    .param-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        width: calc((100% - 20px) / 2);
        margin-bottom: 10px;
      }
      div:nth-last-child(2), div:nth-last-child(1) {
        margin-bottom: 0;
      }
      &.param-container-mobile {
        div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

<i18n>
{
	"en": {
		"Params": "Params",
    "Device Select": "Device Select"
	},
	"zh": {
		"Params": "参数",
    "Device Select": "设备选择"
	}
}
</i18n>

